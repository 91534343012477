import React from 'react';
import UserModel from '../models/user';
import { makeStyles } from '@material-ui/core/styles';
// import { isWhiteSpaceLike } from 'typescript';
import moment from 'moment';

const model = new UserModel();

const convertNumberToDay = (day) => {
  switch (day) {
    case 0: return 'SUN';
    case 1: return 'MON';
    case 2: return 'TUES';
    case 3: return 'WED';
    case 4: return 'THUR';
    case 5: return 'FRI';
    default: return 'SAT';
  }
};
const datesAreOnSameDay = (first, second) => first.getUTCFullYear() === second.getUTCFullYear()
    && first.getUTCMonth() === second.getUTCMonth()
    && first.getUTCDate() === second.getUTCDate();

// TODO: Move this to a model.
const getNonInstaParams = async (businessId, token) => {
  const ttlFollowers = await model.getTotalFollowerCount(businessId, token);
  const totalLikesAndComments = await model.getUserMediaInsights(businessId, token);
  const currentEngagement = ((totalLikesAndComments.totalLikes + totalLikesAndComments.totalComments) / totalLikesAndComments.mediaCount) / ttlFollowers.followersCount || 0;

  return [currentEngagement, totalLikesAndComments.totalLikes, totalLikesAndComments.totalComments, ttlFollowers.followersCount];
};

const createRedGradientStroke = (id = 'defaultChart') => {
  const ctx = document.getElementById(id).getContext('2d');
  const gradientStroke = ctx.createLinearGradient(0, 400, 0, 50);

  gradientStroke.addColorStop(1, 'rgba(72,72,176,0.1)');
  gradientStroke.addColorStop(0, 'rgba(72,72,176,0.0)');
  gradientStroke.addColorStop(0, 'rgba(119,52,169,0)');
  return gradientStroke;
};

const createGreenGradientStroke = (id = 'greenChart') => {
  const ctx = document.getElementById(id).getContext('2d');
  const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

  gradientStroke.addColorStop(1, 'rgba(66,134,121,0.15)');
  gradientStroke.addColorStop(0.4, 'rgba(66,134,121,0.0)');
  gradientStroke.addColorStop(0, 'rgba(66,134,121,0)');
  return gradientStroke;
};
const createPurpleGradientStroke = (id = 'purpleChart') => {
  const ctx = document.getElementById(id).getContext('2d');

  const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

  gradientStroke.addColorStop(1, 'rgba(72,72,176,0.2)');
  gradientStroke.addColorStop(0.2, 'rgba(72,72,176,0.0)');
  gradientStroke.addColorStop(0, 'rgba(119,52,169,0)'); // purple colors
  return gradientStroke;
};
const createBlueBarGradent = (id = 'blueLineChart') => {
  const ctx = document.getElementById(id).getContext('2d');

  const gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

  gradientStroke.addColorStop(1, 'rgba(29,140,248,0.2)');
  gradientStroke.addColorStop(0.4, 'rgba(29,140,248,0.0)');
  gradientStroke.addColorStop(0, 'rgba(29,140,248,0)'); // blue colors
  return gradientStroke;
};
/* helper function to find date differences*/
const dateDiff = (d1, d2) => Math.floor((d2 - d1) / (1000 * 60 * 60 * 24));
/* create and return new array padding missing days*/
const newDataArray = (data) => {
  const newData = [data[0]];

  for (let i = 1; i < data.length; i += 1) {
    const diff = dateDiff(data[i - 1][0], data[i][0]);
    const startDate = new Date(data[i - 1][0]);
    if (diff > 1) {
      for (let j = 0; j < diff - 1; j += 1) {
        const fillDate = new Date(startDate).setDate(startDate.getDate() + (j + 1));
        newData.push([fillDate, data[i - 1][1], true]);
      }
    }
    newData.push(data[i]);
  }
  return newData;
};
const gradientBarChartConfiguration = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  options: {
    spanGaps: true,
  },
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
    callbacks: {
      title(tooltipItems, data) {
        
        return 'Date: ' + new Date(tooltipItems[0].xLabel).toLocaleDateString() + '';
      },
    },
  },
  responsive: true,
  scales: {
    yAxes: [{

      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        suggestedMin: 60,
        suggestedMax: 120,
        padding: 20,
        fontColor: '#9e9e9e',
        callback: function(value) {
          var ranges = [
             { divider: 1e6, suffix: 'M' },
             { divider: 1e3, suffix: 'k' }
          ];
          function formatNumber(n) {
             for (var i = 0; i < ranges.length; i++) {
                if (n >= ranges[i].divider) {
                   return (n / ranges[i].divider).toString() + ranges[i].suffix;
                }
             }
             return n;
          }
          return formatNumber(value);
        }
      },
    }],

    xAxes: [
    {
      type: 'time',
      distribution: 'series',
      time: {
        unit: "month",
        unitStepSize: 1000,
        displayFormats: {
            millisecond: 'MMM DD',
            second: 'MMM DD',
            minute: 'MMM DD',
            hour: 'MMM DD',
            day: 'MMM DD',
            week: 'MMM DD',
            month: 'MMM DD',
            quarter: 'MMM DD',
            year: 'MMM DD',
        },
        parser: function(date) {
          return moment(date).utcOffset('+00:00');
        },
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 7
      }
    }
  ],
  },
};

const testOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  options: {
    spanGaps: true,
  },
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
    callbacks: {
      title(tooltipItems, data) {
        
        return 'Date: ' + new Date(tooltipItems[0].xLabel).toLocaleDateString() + '';
      },
    },
  },
  responsive: true,
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        min: 0,
        precision: 0,
        padding: 20,
        fontColor: '#9a9a9a',
        callback: function(value) {
          var ranges = [
             { divider: 1e6, suffix: 'M' },
             { divider: 1e3, suffix: 'k' }
          ];
          function formatNumber(n) {
             for (var i = 0; i < ranges.length; i++) {
                if (n >= ranges[i].divider) {
                   return (n / ranges[i].divider).toString() + ranges[i].suffix;
                }
             }
             return n;
          }
          return formatNumber(value);
        }
      },
    }],

    xAxes: [
    {
      type: 'time',
      distribution: 'series',
      time: {
        unit: "month",
        unitStepSize: 1000,
        displayFormats: {
            millisecond: 'MMM DD',
            second: 'MMM DD',
            minute: 'MMM DD',
            hour: 'MMM DD',
            day: 'MMM DD',
            week: 'MMM DD',
            month: 'MMM DD',
            quarter: 'MMM DD',
            year: 'MMM DD',
        },
        parser: function(date) {
          return moment(date).utcOffset('+00:00');
        },
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 7
      }
    }
  ],
  },
};
const engagementOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  options: {
    spanGaps: true,
  },
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
    callbacks: {
      label(tooltipItem, data) {
        if (data.datasets.length > tooltipItem.datasetIndex && data.datasets[tooltipItem.datasetIndex].pointBorderColor && data.datasets[tooltipItem.datasetIndex].pointBorderColor[tooltipItem.index] === '#ed4933') {
          return 'Incomplete data: daily data is stored everytime you login';
        }
        return `Engagement: ${Math.round(tooltipItem.yLabel)}%`;
      },
      title(tooltipItems, data) {
        
        return 'Date: ' + new Date(tooltipItems[0].xLabel).toLocaleDateString() + '';
      },
    },
  },
  responsive: true,
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        precision: 1,
        min: 0,
        padding: 20,
        fontColor: '#9a9a9a',
        callback(label, index, labels) {
          return `${label}%`;
        },
      },
    }],

    xAxes: [
      {
        type: 'time',
        distribution: 'series',
        time: {
          unit: "month",
          unitStepSize: 1000,
          displayFormats: {
              millisecond: 'MMM DD',
              second: 'MMM DD',
              minute: 'MMM DD',
              hour: 'MMM DD',
              day: 'MMM DD',
              week: 'MMM DD',
              month: 'MMM DD',
              quarter: 'MMM DD',
              year: 'MMM DD',
          },
          parser: function(date) {
           
            return moment(date).utcOffset('+00:00');
          },
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7
        }
      }
    ],
  },
};
const rhysEngagementOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  options: {
    spanGaps: true,
  },
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
    callbacks: {
      label(tooltipItem, data) {
        if (data.datasets.length > tooltipItem.datasetIndex && data.datasets[tooltipItem.datasetIndex].pointBorderColor && data.datasets[tooltipItem.datasetIndex].pointBorderColor[tooltipItem.index] === '#ed4933') {
          return 'Incomplete data: daily data is stored everytime you login';
        }
        return `Engagement: ${Math.round(tooltipItem.yLabel)}%`;
      },
      title(tooltipItems, data) {
        if(tooltipItems[0].index == 0){
          return "Sunday";
        } else if(tooltipItems[0].index == 1){
          return "Monday"
        } else if(tooltipItems[0].index == 2){
          return "Tuesday"
        } else if(tooltipItems[0].index == 3){
          return "Wednesday"
        } else if(tooltipItems[0].index == 4){
          return "Thursday"
        } else if(tooltipItems[0].index == 5){
          return "Friday"
        } else if(tooltipItems[0].index == 6){
          return "Saturday"
        }
        
        return tooltipItems[0].xLabel;
      },
    },
  },
  responsive: true,
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        precision: 1,
        min: 0,
        padding: 20,
        fontColor: '#9a9a9a',
        callback(label, index, labels) {
          return `${label}%`;
        },
      },
    }],
  },
};
const retentionOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  options: {
    spanGaps: true,
  },
  tooltips: {
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
    callbacks: {
      label(tooltipItem, data) {
        if (data.datasets.length > tooltipItem.datasetIndex && data.datasets[tooltipItem.datasetIndex].pointBorderColor && data.datasets[tooltipItem.datasetIndex].pointBorderColor[tooltipItem.index] === '#ed4933') {
          return 'Incomplete data: daily data is stored everytime you login';
        }
        return `Profile Retention: ${Math.round(tooltipItem.yLabel)}%`;
      },
      title(tooltipItems, data) {
        
        return 'Date: ' + new Date(tooltipItems[0].xLabel).toLocaleDateString() + '';
      },
    },
  },
  responsive: true,
  scales: {
    yAxes: [{
      barPercentage: 1.6,
      gridLines: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: 'transparent',
      },
      ticks: {
        precision: 1,
        min: 0,
        padding: 20,
        fontColor: '#9a9a9a',
        callback(label, index, labels) {
          return `${label}%`;
        },
      },
    }],

    xAxes: [
      {
        type: 'time',
        distribution: 'series',
        time: {
          unit: "month",
          unitStepSize: 1000,
          displayFormats: {
              millisecond: 'MMM DD',
              second: 'MMM DD',
              minute: 'MMM DD',
              hour: 'MMM DD',
              day: 'MMM DD',
              week: 'MMM DD',
              month: 'MMM DD',
              quarter: 'MMM DD',
              year: 'MMM DD',
          },
          parser: function(date) {
            return moment(date).utcOffset('+00:00');
          },
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7
        }
      }
    ],
  },
};
const comments = [{
  username: 'futurecustomer',
  text: 'What is Reach?',
  id: '0',
  replies: [
    {
      username: 'reachsocialgrowth',
      text: 'Reach is a social intelligence platform that not only allow brands to get instantaneous feedback about products, announcements and posts using our in-depth community insights, but also automatically responds to any comments or concerns their community may have using industry specific Natural Language Processing AI.',
    }, {
      username: 'futurecustomer',
      text: 'What is an industry specific Natural Language Processing AI?',
    }, {
      username: 'reachsocialgrowth',
      text: "It's essentially a bot that learns how specific industries talk to each other! We will be able to set your brand up with a bot that works best for you (post beta feature).",
    },
  ],
}, {
  username: 'futurecustomer',
  text: 'How can Reach help my brand?',
  id: '1',
  replies: [
    {
      username: 'reachsocialgrowth',
      text: "Reach's beta platform can help you automatically and naturally respond to comments as well as provide meaningful insights.",
    }, {
      username: 'futurecustomer',
      text: 'Why is this helpful?',
    }, {
      username: 'reachsocialgrowth',
      text: "As your brand grows you will have less time to interact with your community, so we're here to help you out with that 😊",
    },
  ],
}, {
  username: 'futurecustomer',
  text: 'Who is Reach for?',
  id: '2',
  replies: [
    {
      username: 'reachsocialgrowth',
      text: 'Reach is for brands with medium to high-traffic Instagram profiles that either need support responding to comments or making sense of quantitative data.',
    },
  ],
}, {
  username: 'futurecustomer',
  text: "Aren't bots, like Reach, not allowed on Instagram?",
  id: '3',
  replies: [
    {
      username: 'reachsocialgrowth',
      text: "Bots that don't go through Instagram's API (i.e. Take in your username and password rather than using the \"Login with Instagram\" feature) aren't allowed, since we're using the API, our bot is allowed!",
    },
  ],
}, {
  username: 'futurecustomer',
  text: 'Why did you start Reach?',
  id: '4',
  replies: [
    {
      username: 'reachsocialgrowth',
      text: "We recognized that businesses weren't responding to their audience due to the amount of time it takes out of their already busy days. We also recognized that there are a small amount of social tools that not only give quantitative metrics but also explain why certain peaks or valleys in this data are happening. We know brands really struggle with these things, so that's why we're creating solutions to them!",
    },
  ],
}, {
  username: 'futurecustomer',
  text: 'How can I sign up?',
  id: '5',
  replies: [
    {
      username: 'reachsocialgrowth',
      text: `You can sign up for the beta by clicking ${<a href=""> + "this link" + </a>}. We only want legitament businesses and brands using our product, so there's a little bit of an application process.`,
    }, {
      username: 'futurecustomer',
      text: 'What qualifies my brand or business as legitament?',
    }, {
      username: 'reachsocialgrowth',
      text: 'We pretty much just want to confirm that you actually have a brand that supplies value to an engaged community.',
    },
  ],
}, {
  username: 'futurecustomer',
  text: 'How is Reach different from other social tools?',
  id: '6',
  replies: [
    {
      username: 'reachsocialgrowth',
      text: "Firstly, Reach securely logs you in using Instagram's service whereas other automation stores your username and password which could be compromising to your brand.\n\nSecondly, our industry specific AI responds to comments naturally as demonstrated in the \"Connect\" section above.\n\nThirdly, instead of just providing basic quantitative insights, we give you meaningful, in-depth, community analytics that allow you to quickly understand why you're getting so much engagement on that post, or why people aren't liking a certain announcement you just made.",
    },
  ],
}, {
  username: 'futurecustomer',
  text: 'What are you doing with my data?',
  id: '7',
  replies: [
    {
      username: 'reachsocialgrowth',
      text: `This is a big question, so we'll give you a little TLDR. We use your personal data to better your experience on our platform, the comment data to train the AI, and usage data to see what features people are using and how their using them to improve the overall quality of the platform. If you want to look into specifics, please visit our ${<a href="/privacy-policy"> + "privacy policy" + </a>} page.`,
    },
  ],
}, {
  username: 'futurecustomer',
  text: 'How much does the Reach beta cost?',
  id: '8',
  replies: [
    {
      username: 'reachsocialgrowth',
      text: "It's free! ... for now at least. Since this will be our first version the most valuable thing you can give us is your feedback. We will reach out to our clients about price part way through the beta period.",
    },
  ],
},
];

const useCaseDescriptions = {
  "use-case-item-1":{
    "title": "Audience Validation",
    "description": "Using sentiment analysis over time, you are able to quickly figure out whether or not your audience enjoys that new product you just released, a new post format or really anything!",
    "image_alt": "Sentiment Analysis Graph"
  },"use-case-item-2":{
    "title": "Retention Tracking",
    "description": "Using Profile retention, you will be able to see how many followers you have retained over a selected period of time.",
    "image_alt": "Founders Meeting"
  },"use-case-item-3":{
    "title": "Automated Replies",
    "description": "Using our Comment AI you are able to automatically respond to frequently asked questions as well as any reactions your audience may have to your content.",
    "image_alt": "Instagram Comment AI"
  }
};

const sliderStyles = makeStyles((theme) => ({
  root: {
    width: 300,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const teamSliderMarks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 10,
    label: '10',
  },{
    value: 20,
    label: '20',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100+',
  },
];

const likesSliderMarks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 10,
    label: '10',
  },{
    value: 20,
    label: '20',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100+',
  },
];

const commentsSliderMarks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 10,
    label: '10',
  },{
    value: 20,
    label: '20',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100+',
  },
];

const savesSliderMarks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 10,
    label: '10',
  },{
    value: 20,
    label: '20',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100+',
  },
];

// const getStorage = (name) => {
//   if (typeof window !== 'undefined') {
//     return sessionStorage.getItem(name) || null;
//   }
//   return null;
// }
// const setStorage = (name, value) => {
//   sessionStorage.setItem(name, value);
// }
// const removeStorage = (name) => {
//   sessionStorage.removeItem(name);
// }
export {
  convertNumberToDay,
  datesAreOnSameDay,
  newDataArray,
  dateDiff,
  getNonInstaParams,
  createRedGradientStroke,
  createGreenGradientStroke,
  createPurpleGradientStroke,
  createBlueBarGradent,
  gradientBarChartConfiguration,
  testOptions,
  engagementOptions,
  comments,
  useCaseDescriptions,
  sliderStyles,
  teamSliderMarks,
  savesSliderMarks,
  likesSliderMarks,
  commentsSliderMarks,
  retentionOptions,
  rhysEngagementOptions,
  // getStorage,
  // setStorage,
  // removeStorage,
};
